import React from "react"
import TriggerSelect from "../trigger-select"
import Option from "../option"
import { TextField, Button } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes, faPlus } from "@fortawesome/free-solid-svg-icons"

const BotMessage = ({ e, elements, updateField, onTriggerSelect }) => (
  <>
    <div className="element-message">
      <TextField
        className="element-field"
        variant="outlined"
        label="message"
        multiline
        rowsMax="4"
        value={e.message}
        onChange={(ev) => updateField("message", e.id, false, ev.target.value)}
      />
    </div>
    <div className="element-trigger">
      <TriggerSelect
        value={e.trigger}
        elementId={e.id}
        elements={elements}
        onTriggerSelect={onTriggerSelect}
        end={e.end}
      />
    </div>
  </>
)

const UserInput = ({ e, elements, onTriggerSelect, updateField }) => (
  <>
    <div className="element-row">
      <TextField
        className="element-field"
        variant="outlined"
        label="callback"
        multiline
        rowsMax="4"
        value={e.callback}
        onChange={(ev) => updateField("callback", e.id, false, ev.target.value)}
      />
    </div>
    <div className="element-trigger">
      <TriggerSelect
        value={e.trigger}
        elementId={e.id}
        elements={elements}
        onTriggerSelect={onTriggerSelect}
        end={e.end}
      />
    </div>
  </>
)

export const Options = (props) => {
  const { e, updateField, addOption } = props
  const options = e.options || []
  return (
    <>
      <div className="element-options">
        {options.map((o, i) => (
          <Option
            key={`option_${e.id}_${i}`}
            o={o}
            elementId={e.id}
            {...props}
          />
        ))}
      </div>
      <Button
        variant="contained"
        className="add-button"
        onClick={() => addOption(e.id)}
      >
        <FontAwesomeIcon icon={faPlus} /> &nbsp; Add Option
      </Button>
      <div className="element-row">
        <TextField
          className="element-field"
          variant="outlined"
          label="callback"
          multiline
          rowsMax="4"
          value={e.callback}
          onChange={(ev) =>
            updateField("callback", e.id, false, ev.target.value)
          }
        />
      </div>
    </>
  )
}

export const ElementTypes = [
  {
    id: "bot-message",
    component: BotMessage,
    template: { message: "", end: true },
  },
  {
    id: "user-input",
    hint: "user input can be used as {previousValue} in the following step",
    component: UserInput,
    template: { user: true, end: true },
  },
  {
    id: "options",
    component: Options,
    template: { options: [] },
  },
]

const Element = (props) => {
  const { e, removeElement } = props
  const type = getElementType(e)
  return (
    <div className="bot-element">
      <div className="element-id">
        {e.id} – {type.id.toUpperCase()}
      </div>
      {!!type.hint && <div className="element-hint">{type.hint}</div>}
      <div
        className="element-remove-button"
        onClick={() => removeElement(e.id)}
      >
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <type.component {...props} />
    </div>
  )
}

export default Element

export function getElementType(e) {
  let id = "bot-message"
  if (e.hasOwnProperty("options")) id = "options"
  else if (e.user) id = "user-input"
  return ElementTypes.find((t) => t.id === id)
}
