import React from "react"
import ChatBot from "react-simple-chatbot"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import useStatefulSteps from "./stateful-steps"

const TestChatBot = ({ steps, stop }) => {
  const statefulSteps = useStatefulSteps(steps)
  return (
    <div className="chat-bot-container">
      <div className="chat-bot-close-button" onClick={() => stop()}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <ChatBot steps={statefulSteps} />
    </div>
  )
}

export default TestChatBot
