import React, { useState, useEffect } from "react"
import TestChatBot from "./test-chat-bot"
import { TextField, Button } from "@material-ui/core"
import JSON5 from "json5"
import "./app.scss"
import Element, { ElementTypes } from "./elements/elements"
import copyToClipboard from "./copy-to-clipboard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCopy,
  faShare,
  faPlus,
  faRocket,
  faHandPaper,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"

const LOCALSTORAGE_KEY = "code"
const title = "дekóder Chatbot-Editor"

const App = () => {
  const $_GET = useGet()
  const [code, setCode] = useState(
    $_GET.steps || window.localStorage.getItem(LOCALSTORAGE_KEY) || "[]"
  )
  const [testing, setTesting] = useState(false)
  const [identities, setIdentities] = useState([{ name: "MyBot", imgUrl: "" }])

  function getNextElementId() {
    const parsedCode = parse(code)
    return parse(code).length ? Math.max(...parsedCode.map((e) => e.id)) + 1 : 1
  }

  function getParsedCode() {
    return parse(code)
  }

  function getShareLink() {
    return `${window.location.origin}/?steps=${encodeURIComponent(code)}`
  }

  function copyCodeToClipboard() {
    copyToClipboard(code)
    window.alert("Code copied to clipboard")
  }

  function copyShareLinkToClipboard() {
    copyToClipboard(getShareLink())
    window.alert("Share-Link copied to clipboard")
  }

  function updateCode(newCode) {
    const stringifiedCode = JSON5.stringify(newCode, null, 2)
    setCode(stringifiedCode)
  }

  useEffect(() => {
    window.localStorage.setItem(LOCALSTORAGE_KEY, code)
  }, [code])

  function addElement(obj) {
    if (typeof obj !== "object") return
    obj.id = getNextElementId()
    updateCode([...getParsedCode(), obj])
  }

  function removeElement(id) {
    if (!window.confirm(`Remove Element No ${id}: Are you sure?`)) return
    const filteredCode = getParsedCode().filter((e) => e.id !== id)
    const cleanedCode = filteredCode.map((e) => ({
      ...e,
      trigger: e.trigger === id ? "" : e.trigger,
      options: e.options
        ? e.options.map((o) => ({
            ...o,
            trigger: o.trigger === id ? "" : o.trigger,
          }))
        : undefined,
    }))
    updateCode(cleanedCode)
  }

  function addOption(id) {
    const newCode = getParsedCode().slice()
    const element = newCode.find((e) => e.id === id)
    if (!element) return
    const newValue = element.options.length
      ? Math.max(...element.options.map((o) => o.value)) + 1
      : 1
    element.options.push({ value: newValue, label: "", end: "true" })
    updateCode(newCode)
  }

  function removeOption(id, value) {
    if (
      !window.confirm(
        `Remove Option No ${value} in Element No ${id}: Are you sure?`
      )
    )
      return
    const newCode = getParsedCode().slice()
    const element = newCode.find((e) => e.id === id)
    if (!element) return
    element.options = element.options.filter((o) => o.value !== value)
    updateCode(newCode)
  }

  function updateField(field, elementId, optionId, value) {
    const newCode = getParsedCode().slice()
    const element = newCode.find((e) => e.id === elementId)
    if (!element) return
    if (!optionId) {
      if (field === "trigger") delete element.end
      element[field] = value
      if (value === false) delete element[field]
    } else {
      const option = element.options.find((o) => o.value === optionId)
      if (!option) return
      if (field === "trigger") delete option.end
      option[field] = value
      if (value === false) delete option[field]
    }
    updateCode(newCode)
  }

  function onTriggerSelect(elementId, optionId, value) {
    let callback = () => {}
    if (value === "newBotMessage") {
      // callback = addBotMessage
      value = getNextElementId()
    } else if (value === "newOptions") {
      // callback = addOptions
      value = getNextElementId()
    } else if (value === "end") {
      value = false
      callback = () => updateField("end", elementId, optionId, true)
    }
    updateField("trigger", elementId, optionId, value) // callback
    callback()
  }

  function renderElements(elements) {
    return elements.map((e, i) => (
      <Element
        e={e}
        elements={elements}
        key={`element_${i}`}
        removeElement={removeElement}
        addOption={addOption}
        removeOption={removeOption}
        updateField={updateField}
        onTriggerSelect={onTriggerSelect}
      />
    ))
  }

  function addNewIdentity() {
    const name = `NewBot${identities.length}`
    setIdentities((idt) => [...idt, { name, imgUrl: "" }])
  }

  function removeIdentity(name) {
    setIdentities((idt) => [...idt.filter((i) => i.name !== name)])
  }

  const elements = getParsedCode()
  return (
    <div className="app">
      <h1>{title}</h1>
      <div className="identities-container">
        {identities.map((idt, i) => (
          <li key={i}>
            {idt.name}
            <button onClick={() => removeIdentity(idt.name)}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </li>
        ))}
        <Button
          variant="contained"
          className="add-button inline"
          onClick={addNewIdentity}
        >
          add Identity
        </Button>
      </div>
      <TextField
        value={code}
        onChange={(e) => setCode(e.target.value)}
        className="code-field"
        variant="outlined"
        label="Code"
        multiline
        rowsMax="10"
      />
      <div className="button-container">
        <Button
          variant="contained"
          className="add-button inline"
          onClick={copyCodeToClipboard}
        >
          <FontAwesomeIcon icon={faCopy} /> Copy Code to Clipboard
        </Button>
        <Button
          variant="contained"
          className="add-button inline"
          onClick={copyShareLinkToClipboard}
        >
          <FontAwesomeIcon icon={faShare} /> Copy Share-Link to Clipboard
        </Button>
      </div>
      {elements.length ? renderElements(elements) : "Please enter valid code."}
      <div className="button-container">
        {ElementTypes.map((t, i) => (
          <Button
            key={i}
            variant="contained"
            className="add-button inline"
            onClick={() => addElement(t.template)}
          >
            <FontAwesomeIcon icon={faPlus} /> &nbsp; Add {t.id}
          </Button>
        ))}
      </div>
      <Button
        variant="contained"
        className="add-button"
        onClick={() => setTesting((t) => !t)}
      >
        <FontAwesomeIcon icon={testing ? faHandPaper : faRocket} /> &nbsp;{" "}
        {testing ? "Stop Test" : "Start Test"}
      </Button>
      {testing && (
        <TestChatBot steps={elements} stop={() => setTesting(false)} />
      )}
    </div>
  )
}

export default App

function parse(code) {
  let result = []
  try {
    result = JSON5.parse(code)
  } catch (e) {
    console.log(e)
  }
  return result
}

function useGet() {
  let $_GET = {}
  window.location.search
    .substr(1)
    .split("&")
    .forEach((part) => {
      if (part)
        $_GET[decodeURIComponent(part.split("=")[0])] = decodeURIComponent(
          part.split("=")[1]
        )
    })
  return $_GET
}
