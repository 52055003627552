import React from "react"
import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core"
import { getElementType } from "./elements/elements"

const TriggerSelect = ({
  value,
  elementId,
  optionId,
  elements,
  onTriggerSelect,
  end,
}) => {
  elements = elements || []
  return (
    <FormControl variant="outlined" className="trigger-select">
      <InputLabel>trigger</InputLabel>
      <Select
        value={end ? "end" : value}
        onChange={(e) => onTriggerSelect(elementId, optionId, e.target.value)}
        input={<OutlinedInput labelWidth={60} />}
      >
        <MenuItem value="newBotMessage">
          <strong>New Bot-Message</strong>
        </MenuItem>
        <MenuItem value="newOptions">
          <strong>New Options</strong>
        </MenuItem>
        <MenuItem value="newUserInput">
          <strong>New User-Input</strong>
        </MenuItem>
        {elements.map((e, i) => (
          <MenuItem value={e.id} key={`trigger_${elementId}_${optionId}_${i}`}>
            {e.id} – {e.message || getElementType(e).id.toUpperCase()}
          </MenuItem>
        ))}
        <MenuItem value="end">
          <strong>End</strong>
        </MenuItem>
      </Select>
    </FormControl>
  )
}

export default TriggerSelect
