import React from "react"
import TriggerSelect from "./trigger-select"
import { TextField } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

const Option = ({
  o,
  elementId,
  removeOption,
  updateField,
  elements,
  onTriggerSelect,
}) => (
  <div className="option">
    <div className="option-cell option-label">
      <TextField
        className="option-field"
        variant="outlined"
        label="label"
        value={o.label}
        onChange={(ev) =>
          updateField("label", elementId, o.value, ev.target.value)
        }
      />
    </div>
    <div className="option-cell option-value">
      <TextField
        className="option-field"
        variant="outlined"
        label="value"
        value={o.value}
        onChange={(ev) =>
          updateField("value", elementId, o.value, ev.target.value)
        }
      />
    </div>
    <div className="option-cell option-trigger">
      <TriggerSelect
        value={o.trigger}
        elementId={elementId}
        optionId={o.value}
        elements={elements}
        onTriggerSelect={onTriggerSelect}
        end={o.end}
      />
    </div>
    <button
      className="option-remove-button"
      onClick={() => removeOption(elementId, o.value)}
    >
      <FontAwesomeIcon icon={faTimes} />
    </button>
  </div>
)

export default Option
