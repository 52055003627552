import React, { useMemo, useEffect } from "react"

let state = {}
const setState = (arg = {}) => {
  const newState = typeof arg === "function" ? arg(state) : arg
  state = { ...state, ...newState }
  console.log("NEW STATE", state)
}

export default function useStatefulSteps(steps = []) {
  const statefulSteps = useMemo(
    () =>
      steps.map((step) => {
        const patchedStep = { ...step }
        if (step.message) {
          try {
            // eslint-disable-next-line no-eval
            step.message = eval(step.message)
          } catch (e) {
            console.log(e)
          }
        }
        if (typeof step.message === "function")
          patchedStep.message = (arg) =>
            step.message({ ...arg, state, setState })
        if (typeof patchedStep.trigger === "function")
          patchedStep.trigger = (arg) =>
            step.trigger({ ...arg, state, setState })
        if (typeof step.callback === "string") {
          try {
            // eslint-disable-next-line no-eval
            step.callback = eval(step.callback)
          } catch (e) {
            console.log(e)
          }
          const isAsync = step.callback.constructor.name === "AsyncFunction"
          if (step.options) {
            patchedStep.options = step.options.map((o) => ({
              ...o,
              trigger: (arg) => {
                step.callback({ ...arg, state, setState })
                return o.trigger
              },
            }))
          } else if ((step.user || step.message) && !isAsync) {
            patchedStep.trigger = (arg) => {
              step.callback({ ...arg, state, setState })
              return step.trigger
            }
          } else if (step.message && isAsync) {
            patchedStep.waitAction = true
            patchedStep.asMessage = true
            patchedStep.component = (
              <CustomComponent
                callback={step.callback}
                state={state}
                setState={setState}
              >
                {step.message}
              </CustomComponent>
            )
            delete patchedStep.message
          }
          delete patchedStep.callback
        }
        return patchedStep
      }),
    [steps]
  )
  console.log(statefulSteps)
  return statefulSteps
}

function CustomComponent({
  children,
  callback,
  previousStep,
  state,
  setState,
  triggerNextStep,
}) {
  useEffect(() => {
    const action = async () => {
      const previousValue = previousStep.value
      await callback({ previousValue, state, setState })
      triggerNextStep()
    }
    action()
  }, [triggerNextStep, callback, state, setState, previousStep])
  return <>{children}</>
}
